<template>
  <div
    class="text-s-regular"
    v-html="locale.termsAndPolicy"
  />
</template>

<script>
export default {
  props: {
    twitch: VueTypes.bool,
    localePath: VueTypes.string,
  },
  computed: {
    locale () {
      if (this.localePath) {
        return this.$t(this.localePath)
      }
      return {
        termsAndPolicy: this.$locale('termsAndPolicy'),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-s-regular {
  ::v-deep {
    a {
      display: inline;
      border-bottom: 1px solid gray;
    }
  }
}
</style>

<template>
  <AuthError>
    {{ locale.accountBlocked }}
    <a
      :href="$t('links.discord')"
      target="_blank"
    >
      {{ locale.contactSupport }}
    </a>.
  </AuthError>
</template>

<script setup>
import { useLocale } from 'lib/helpers/useLocale'
import { computed } from 'vue'

import AuthError from './AuthError'

const locale = computed(() => ({
  accountBlocked: useLocale('accountBlocked'),
  contactSupport: useLocale('contactSupport'),
}))
</script>
